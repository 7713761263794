<template>
  <div>
    <iframe
      v-if="checkAcessoCredor"
      :src="powerBi"
      frameborder="0"
      style="width: 100%; height: 80vh"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkCebama: 'https://app.powerbi.com/view?r=eyJrIjoiOTcxYTEyOTgtOGQxZC00ZTEyLWJiYzgtMmZjZDU3NTk4NGNiIiwidCI6ImJkMzdmNTNmLWZiM2YtNGY2NS1hZWYxLTRkYWViYTIwNDA3MSJ9',
      linkDrummond: 'https://app.powerbi.com/view?r=eyJrIjoiN2YyNGRiOGUtODQ0OC00N2RiLWJlMWQtYzkwYjZhODczZjY1IiwidCI6ImJkMzdmNTNmLWZiM2YtNGY2NS1hZWYxLTRkYWViYTIwNDA3MSJ9',
      linkSalesiana: 'https://app.powerbi.com/view?r=eyJrIjoiYmM0YWVkYWEtYTFhMS00YTU2LWIzYjgtY2I0ODBlYWI2YWFlIiwidCI6ImJkMzdmNTNmLWZiM2YtNGY2NS1hZWYxLTRkYWViYTIwNDA3MSJ9',
      linkUninovo: 'https://app.powerbi.com/view?r=eyJrIjoiNGViNTVkYWYtOWIyNy00NTYzLWFhODUtOGY4MjEzYjBlZmExIiwidCI6ImJkMzdmNTNmLWZiM2YtNGY2NS1hZWYxLTRkYWViYTIwNDA3MSJ9',
      powerBi: null,
    }
  },
  computed: {
    checkAcessoCredor() {
      const { credores } = localStorage
      const { userId } = localStorage
      if (credores) {
        if (parseInt(userId) === 387) {
          this.powerBi = this.linkCebama
        } else if (parseInt(userId) === 403 || parseInt(userId) === 404 || parseInt(userId) === 405) {
          this.powerBi = this.linkUninovo
        } else if (parseInt(userId) === 406 || parseInt(userId) === 407 || parseInt(userId) === 408 || parseInt(userId) === 409) {
          this.powerBi = this.linkDrummond
        } else if (parseInt(userId) === 317 || parseInt(userId) === 316) {
          this.powerBi = this.linkSalesiana
        }
        return true
      }
      return false
    },
  },

}
</script>
